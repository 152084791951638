.table-logos-card {
  margin-top: 200px;
  position: relative;
  margin-bottom: 200px;
}

.small-logo-scheme {
  position: absolute;
  right: 0;
  left: 91%;
  top: -16%;
  width: 140px;
}

.table-logos-services {
  display: flex;
  align-items: center;
  width: 72%;
  background-color: #4c39a0;
  border-radius: 10px;
  margin-left: 248px;
}

.logos-services-container {
  width: 25%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logos-services {
  height: 58px;
}

.nessus {
  height: 45px;
}

.nmap {
  width: 90px;
  height: 80px;
}

.burpsuite {
  height: 310px;
  width: 420px;
}

.cobalt {
}

@media (max-width: 768px) {
  .table-logos-card {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .table-logos-services {
    margin-left: 10px;
    width: 95%;
  }
  .logos-services-container {
    height: 80px;
  }
  .logos-services {
    height: 20px;
  }
  .nessus {
    height: 25px;
  }
  .burpsuite {
    height: 150px;
  }
  .nmap {
    width: 60px;
    height: 70px;
  }
  .small-logo-scheme {
    visibility: hidden;
  }
}

.scheme {
  display: flex;
  width: 54%;
  align-items: center;
  margin-left: 416px;
}

.scheme-branch {
  width: 100%;
  height: 70px;
  border-bottom: 2px solid #4c39a0;
}

.scheme-branch-f {
  border-left: 2px solid #4c39a0;
  border-right: 2px solid #4c39a0;
}
.scheme-branch-s {
}
.scheme-branch-t {
  border-left: 2px solid #4c39a0;
  border-right: 2px solid #4c39a0;
}

.arrow-down-fragile {
  width: 102px;
}

.arrow-down-ico {
  margin-left: -25px;
  margin-top: 32px;
  margin-bottom: 44px;
  transform: rotate(90deg);
  width: 80px;
}

.scheme-img {
  width: 615px;
}

@media (max-width: 768px) {
  .scheme {
    margin-left: 50px;
    width: 75%;
  }
  .arrow-down-ico {
    width: 60px;
    margin-top: 24px;
    margin-left: 10px;
  }
  .scheme-img {
    width: 90%;
    margin-left: 5px;
  }
}

.scheme-benefits-container {
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 20px;
  padding-top: 100px;
  padding-bottom: 300px;
}

.scheme-container-img {
  display: flex;
  justify-content: center;
}

.dependencies-scheme {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 101px;
  padding-top: 52px;
}

.scheme-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.scheme-benefits {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 300px;
  margin-top: 20px;
  gap: 30px;
  height: 315px;
}

.chat-arrow {
  position: absolute;
  transform: rotate(180deg);
  top: -72px;
  left: 72px;
}

.absolute-arrow-common {
  position: absolute;
  right: -104px;
  top: 40%;
  /* left: 70px; */
}
.absolute-arrow-common-second {
  position: absolute;
  left: -104px;
  transform: rotate(180deg);
  top: 40%;
  /* left: 70px; */
}

.absolute-arrow-chat {
  position: absolute;
  bottom: -72px;
  left: 73px;
}

.benefits-container-chat {
  position: relative;
}

.benefits-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  width: 250px;
  align-items: center;
  padding: 10px;
  height: 70px;
  border-radius: 5px;
  background-color: #4c39a0;
  border: 3px solid #171c2b;
}

.benefits-ico-frame {
  padding: 5px;
  background-color: #171c2b;
  border: 3px solid #171c2b;
  border-radius: 10px;
}

.deps-docs-container {
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.benefits-ico {
  width: 30px;
}

.benefit-txt {
  margin: 0;
}

@media (max-width: 768px) {
  .scheme-second {
    padding-top: 0;
  }
  .scheme-container-img {
    flex-direction: column;
  }
  .scheme-benefits-container {
    flex-direction: column;
    padding-bottom: 100px;
  }
  .benefits-container {
    justify-content: center;
    flex-direction: column;
    width: 25%;
    height: 120px;
    padding: 10px 0;
    font-size: 12px;
    gap: 2px;
    height: auto;
  }
  .arrow-down-fragile {
    rotate: 90deg;
    width: 60px;
    left: 20%;
    top: -40%;
  }
  .dependencies-scheme {
    flex-direction: row;
    padding-left: 29px;
    gap: 70px;
    width: 100%;
    padding-top: 22px;
  }
  .absolute-arrow-chat {
    top: 200%;
  }
  .chat-arrow {
    transform: rotate(0);
    top: 0;
  }
  .scheme-benefits {
    width: 90%;
    height: auto;
    gap: 10px;
    flex-direction: row;
  }
  .benefits-ico-frame {
    height: 30px;
    width: 30px;
  }
  .benefits-ico {
    height: 30px;
    width: 30px;
  }
  .absolute-arrow-common {
    top: 130%;
    left: 11px;
  }
}

.responsible-benefits {
  padding: 30px 30px;
  flex-direction: column;
  height: 300px;
  margin-left: 80px;
  background: #171c2b;
  border-top: 4px solid #4c39a0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  width: 325px;
}

.responsible-person {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.responsible-person-txt {
  font-size: 17px;
}

.person {
  margin-top: 4px;
  width: 30px;
  margin-right: 15px;
}

.control-logo {
  width: 190px;
}

.control-line {
  height: 2px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.178);
  width: 230px;
}

@media (max-width: 768px) {
  .responsible-benefits {
    height: auto;
    margin-left: 0;
    margin-top: 50px;
    width: 70%;
    font-size: 16px;
  }
  .responsible-person-txt {
    font-size: 14px;
  }
  .person {
    width: 20px;
  }
  .control-logo {
    padding-bottom: 10px;
    width: 150px;
  }
}
