.sidebar-footer {
  margin-top: 50px;
  border-top: 1px solid #1e142e;
  color: #b7afb6;
  width: 100%;
}

.sidebar-footer .sidebar-footer-bottom-bar {
  padding: 10px 40px 20px;
  background-color: black;
  text-align: center;
}

.sidebar-footer-company-info {
  padding: 20px 40px 0;
  background-color: black;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}
