.links {
  position: absolute;
  bottom: 4%;
  left: 3%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.link {
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 4px;
  text-shadow: 1px 1px 15px #924df9;
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  .link {
    text-align: left;
    font-size: 27px;
  }
}
