.textBlock {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-size: cover;
  background-image: url("./../../../../assets/images/airin_bg.png");
}
