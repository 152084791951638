.App {
  text-align: center;
  overflow-y: hidden;
  position: relative;
}

body {
  padding: 0;
  margin: 0;
}
