@font-face {
  font-family: "Cygre";
  src: local("Cygre Bold"),
  local("Cygre-Bold"),
  url("../../assets/fonts/Cygre-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Cygre";
  src: local("Cygre Light"),
  local("Cygre-Light"),
  url("../../assets/fonts/Cygre-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Cygre";
  src: local("Cygre Regular"),
  local("Cygre-Regular"),
  url("../../assets/fonts/Cygre-Regular.ttf");
  font-weight: normal;
}

.main {
  background-color: #110c1a;
  color: white;
  font-family: "Cygre", sans-serif;
}

.mainPage__container {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  position: relative;
}

.background__space {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

.airin {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 25%;
  right: 25%;
}

.glitch {
  position: relative;
  overflow: hidden;
}
.glitch img {
  position: relative;
  z-index: 1;
  display: block;
}
.glitch__layers {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.glitch__layer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("../../assets/images/space_bg.jpg");
  background-repeat: no-repeat;
  background-position: 0 0;
}
.glitch__layer:nth-child(1) {
  transform: translateX(-5%);
  animation: glitch-anim-1 2s infinite linear alternate;
}
.glitch__layer:nth-child(2) {
  transform: translateX(3%) translateY(3%);
  animation: glitch-anim-2 1.5s -0.8s infinite linear alternate;
}
.glitch__layer:nth-child(3) {
  transform: translateX(5%);
  animation: glitch-anim-flash 1s infinite linear;
}
@keyframes glitch-anim-1 {
  0% {
    clip-path: polygon(0 0%, 100% 0%, 100% 5%, 0 5%);
  }
  10% {
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }
  20% {
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }
  30% {
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }
  40% {
    clip-path: polygon(0 35%, 100% 35%, 100% 35%, 0 35%);
  }
  50% {
    clip-path: polygon(0 45%, 100% 45%, 100% 46%, 0 46%);
  }
  60% {
    clip-path: polygon(0 50%, 100% 50%, 100% 70%, 0 70%);
  }
  70% {
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  80% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  90% {
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  100% {
    clip-path: polygon(0 60%, 100% 60%, 100% 70%, 0 70%);
  }
}
@keyframes glitch-anim-2 {
  0% {
    clip-path: polygon(0 15%, 100% 15%, 100% 30%, 0 30%);
  }
  15% {
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }
  25% {
    clip-path: polygon(0 8%, 100% 8%, 100% 20%, 0 20%);
  }
  30% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  45% {
    clip-path: polygon(0 45%, 100% 45%, 100% 45%, 0 45%);
  }
  50% {
    clip-path: polygon(0 50%, 100% 50%, 100% 57%, 0 57%);
  }
  65% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  75% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  80% {
    clip-path: polygon(0 40%, 100% 40%, 100% 60%, 0 60%);
  }
  95% {
    clip-path: polygon(0 45%, 100% 45%, 100% 60%, 0 60%);
  }
  100% {
    clip-path: polygon(0 11%, 100% 11%, 100% 15%, 0 15%);
  }
}
@keyframes glitch-anim-flash {
  0% {
    opacity: 0.2;
  }
  30%,
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .background__space {
    overflow-y: hidden;
    max-width: 800px;
  }
  .airin {
    max-width: 100%;
    height: 100vh;
    object-fit: cover;

    left: -0%;
    top: 00%;

    right: -5%;
  }
}
