@font-face {
  font-family: "Cygre";
  src: url("../../../assets/fonts/Cygre-Bold.ttf");
}

@font-face {
  font-family: "Cygre-Light";
  src: url("../../../assets/fonts/Cygre-Light.ttf");
}

@font-face {
  font-family: "Cygre-Norm";
  src: url("../../../assets/fonts/Cygre-Regular.ttf");
}

.apc-problems {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  gap: 0;
  font-size: 27px;
  align-items: center;
}

.APC-problems-hero {
  position: absolute;
  bottom: 0;
  left: 30.1%;
}

.apc-problems-logo {
  position: absolute;
  width: 150px;
  top: 3%;
  left: 90%;
}

.apc-problems-problematics {
  color: #a8a4d4;
  width: 50%;
  height: 100%;
}
.apc-problems-solution {
  width: 50%;
  height: 100%;
  background-color: #4c39a0;
  color: white;
}

.apc-title {
  text-align: left;
  font-family: "Cygre";
  font-weight: 500;
  font-size: 74px;
  margin-top: 100px;
  margin-left: 40px;
}

.apc-problems-list {
  text-align: left;
  gap: 20px;
  margin-top: 60px;
  width: 50%;
  margin-left: 30px;
}

.apc-solution {
  text-align: left;
  margin-left: 40px;
  margin-top: 60px;
}

.apc-solution-four {
  display: flex;
  align-items: center;
  margin-left: 120px;
  text-align: left;
  gap: 10px;
  margin-top: 40px;
}

.apc-solution-group-key {
  font-size: 96px;
  font-family: "Cygre-Norm";
  /* line-height: 105px; */
}

.apc-solution-sfa {
  margin-top: 40px;
  display: flex;
  text-align: left;
  align-items: center;
  margin-left: 200px;
}

.apc-solution-group-AAC {
  text-align: left;
  margin-top: 40px;
  margin-left: 280px;
}

@media (max-width: 768px) {
  .apc-problems {
    height: 200vh;
    flex-direction: column;
    font-size: 18px;
  }
  .apc-title {
    margin-top: 40px;

    font-size: 40px;
    margin-left: 10px;
  }

  .apc-problems-logo {
    visibility: hidden;
  }
  .APC-problems-hero {
    visibility: hidden;
  }
  .apc-problems-problematics {
    width: 100%;
    height: 50%;
  }
  .apc-problems-list {
    margin-left: 5px;
    width: 70%;
    font-size: 18px;
    margin-bottom: 100px;
    margin-top: 30px;
  }

  .apc-problems-solution {
    width: 100%;
    height: 100vh;
  }
  .apc-solution {
    margin-left: 10px;
    margin-top: 30px;
  }
  .apc-solution-four {
    margin-left: 10px;
    text-align: left;
    gap: 10px;
    margin-top: 10px;
  }
  .apc-solution-group-key {
    font-size: 60px;
  }
  .apc-solution-sfa {
    margin-top: 10px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-left: 10px;
  }

  .apc-solution-group-AAC {
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
  }
}
